<template>
  <base-section
    id="we-help-your-success"
    class="pt-8"
  >
    <v-container>
      <v-row align="start">
        <v-col
          class="fluid pa-0 ma-0"
          cols="12"
          md="12"
        >
          <div class="text-center">
            <div
              class="base-avatar--outlined base-avatar d-inline-flex"
            >
              <v-avatar
                color="secondary"
                class="base-avatar__outline"
                style="opacity: .4"
                size="65.333"
              />

              <v-avatar
                color="secondary"
                class="base-avatar__avatar"
                style="margin: 4.7px 0 0 4.7px"
                size="56"
              >
                <v-img
                  max-height="40"
                  :src="require(`@/assets/logo-dark.svg`)"
                  flat
                  class="ma-1 mx-0"
                  contain
                />
              </v-avatar>
            </div>
          </div>

          <base-section-heading
            title="Aptamimetics"
            dark
            class="mt-4"
            color="secondary"
          />
          <base-body
            class="text-justify ma-4"
          >
            We are a young biotech company based in Freiburg, Germany. The founding team came together as Aptamimetics in late 2020 to realize the idea of aptamer based growth factors for cell cultivation that outperform existing solutions in all respects. With our products, we specifically target biotechnological companies that rely on economic and robust cell cultivation processes. We believe that their cultivated meat, advanced cell-based therapies and tissue engineered products will improve the lives of numerous customers and patients. With our own chemically-defined and low-cost products, we want to help facilitate the market entry of many of these end-user products.
            Today, we are active in our laboratories in Freiburg to make this vision a reality.
          </base-body>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="fluid"
          cols="12"
          md="6"
        >
          <base-section-heading
            title="Our Mission"
            icon="fal fa-mountain"
            outlined
          />
          <base-body class="text-justify px-2">
            High-quality cell based products require high-quality and highly standardized raw materials without the risk of biological contamination.
            Therefore, we develop bioactive aptamers as fully synthetic biotechnological raw materials. Unlike existing proteinogenic raw materials, our unique products are robust, batch-homogenious and biologically safe. Morevoer, our products are also highly cost efficient.
          </base-body>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <base-section-heading
            title="Our Vision"
            icon="fal fa-telescope"
            outlined
          />
          <base-body class="text-justify px-2">
            Alternative protein based food , such as cultivated meat, offer a new perspective on sustainable and clean food for large numbers of people.
            In addition, advanced therapies and new types of biopharmaceuticals offer a unique opportunity to provide therapy to many patients for whom previously there were only poor or no treatment options.
            With our products, we aim to enable biotechnological manufacturers to develop and produce better products for customers and patients.
          </base-body>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWeAreAptamimetics',
    data: () => ({
      video: 'https://www.youtube.com/embed/J74M2x8Viiw?si=ZeZ0RF_DD6QMnlyH', // Replace with your YouTube video ID
      width: 560, // Set your preferred width
      height: 315, // Set your preferred height
    }),

  }
</script>

<style lang="sass">
  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &--outlined
      background-color: #FFFFFF

      .base-avatar__avatar
        border: thin solid rgba(0, 0, 0, .12) !important

  .containerYT
    position: relative
    width: 100%
    height: 0
    padding-bottom: 56.25%

  .videoYT
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
</style>
